@import './vars';
@import './keyframes.scss';

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html,
body {
    position: relative;
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: $dark;
    transition: overflow 0.5s ease;
    -webkit-font-smoothing: antialiased;

    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.01em;
    }
}

// HEADINGS STYLES
// ------------------------------------------------------------------------------------------------

.heading {
    font-weight: 600;
    margin: 0;

    &--1 {
        text-align: center;
        font-size: 4.75rem;
        line-height: 102%;
        letter-spacing: -0.05em;
        margin: 0;
        font-weight: 600;
    }

    &--2 {
        font-size: 3.25rem;
        line-height: 114%;
        letter-spacing: -0.05em;
        margin: 0;
        font-weight: 600;
    }

    &--3 {
        font-size: 2.75rem;
        line-height: 120%;
        letter-spacing: -0.05em;
        margin: 0;
        font-weight: 600;
    }

    &--4 {
        font-size: 2.5rem;
        line-height: 120%;
        letter-spacing: -0.03em;
        margin: 0;
        font-weight: 600;
    }

    &--5 {
        font-size: 1.75rem;
        line-height: 120%;
        letter-spacing: -0.03em;
        font-weight: 600;
    }
}

.excerpt {
    color: $excerpt;
    font-size: 20px;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: -0.01em;
    padding-bottom: 2rem;
}

* {
    box-sizing: border-box;
}

.main {
    flex-grow: 1;
    background-color: #fff;
}

.gallery-autoplay-swiper .swiper-wrapper {
    transition-timing-function: linear !important;
}

input[type='search']::-webkit-search-cancel-button {
    display: none;
}

input[type='search']::-ms-clear {
    display: none;
}

input[type='search']::-moz-search-cancel-button {
    display: none;
}

button {
    background: unset;
    border: none;
    padding: 0;
    display: block;
}

// .loader__wrapper {
//     position: relative;
//     width: 100%;
//     height: 100vh;
//     background: rgb(244 246 255 / 70%);
// }

.exit-preview {
    z-index: 1000000000;
    display: block;
    background: rgba($color: #b1b1b1, $alpha: 0.5);
    color: #000000;
    font-size: 0.625rem;
    font-weight: 600;
    padding: 0.3rem 1rem;
    border-radius: 0.375rem;
    position: fixed;
    right: 4px;
    bottom: 4px;
    transition:
        color 0.2s ease,
        background-color 0.2s ease;

    &:hover {
        color: white;
        background-color: red;
    }
}

.dropzone {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    outline: none;
    border-radius: 4px;
    background-image: repeating-linear-gradient(
            226deg,
            #b6b9c5,
            #b6b9c5 8px,
            transparent 8px,
            transparent 16px,
            #b6b9c5 16px
        ),
        repeating-linear-gradient(
            316deg,
            #b6b9c5,
            #b6b9c5 8px,
            transparent 8px,
            transparent 16px,
            #b6b9c5 16px
        ),
        repeating-linear-gradient(
            46deg,
            #b6b9c5,
            #b6b9c5 8px,
            transparent 8px,
            transparent 16px,
            #b6b9c5 16px
        ),
        repeating-linear-gradient(
            136deg,
            #b6b9c5,
            #b6b9c5 8px,
            transparent 8px,
            transparent 16px,
            #b6b9c5 16px
        );
    background-size:
        1px 100%,
        100% 1px,
        1px 100%,
        100% 1px;
    background-position:
        0 0,
        0 0,
        100% 0,
        0 100%;
    background-repeat: no-repeat;
    background-color: #fdfdfd;
}

input:auto-fill {
    background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $dark;
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
    box-shadow: 0 0 0px 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
}

// Exit Preview Mode Button
// --------------------------------------------------

.exit-preview {
    z-index: 1000000000;
    display: block;
    background: rgba($color: #b1b1b1, $alpha: 0.5);
    color: #000000;
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    padding: 0.3rem 1rem;
    border-radius: 0.375rem;
    position: fixed;
    right: 4px;
    bottom: 4px;
    transition:
        color 0.2s ease,
        background-color 0.2s ease;

    &:hover {
        color: white;
        background-color: red;
    }
}

// Error Pages
// --------------------------------------------------

.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.error-page-wrapper {
    width: 100%;
}

.error-page {
    display: flex;
    flex-direction: column;

    &__title {
        font-size: 48px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 20px;
        letter-spacing: -0.05em;
        opacity: 0;
        animation: fadeIn 0.6s ease-in forwards;
    }

    &__desc {
        max-width: 840px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 24px;
        opacity: 0;
        animation: fadeIn 0.55s ease-in forwards;
        animation-delay: 0.15s;
        letter-spacing: -0.02em;
    }

    &__link {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark;
        background-color: #ededed;
        padding: 17px 28px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: -0.03em;
        transition: background-color 0.4s ease;
        border-radius: 30px;
        opacity: 0;
        animation: fadeIn 0.5s ease-in forwards;
        animation-delay: 0.25s;

        &:hover {
            background-color: #e4e4e4;
        }
    }
}

@media (max-width: 1700px) {
    .heading--1 {
        font-size: 4.25rem;
    }

    .heading--2 {
        font-size: 3.25rem;
    }

    .heading--3 {
        font-size: 2.75rem;
    }

    .heading--4 {
        font-size: 2.25rem;
    }

    .excerpt {
        font-size: 1.25rem;
    }
}

@media (max-width: 1600px) {
    .heading--1 {
        font-size: 4rem;
    }
}

@media (max-width: 1480px) {
    .heading--1 {
        font-size: 3.5rem;
    }

    .heading--2 {
        font-size: 3rem;
    }

    .heading--3 {
        font-size: 2.5rem;
    }

    .excerpt {
        font-size: 1.125rem;
        padding-bottom: 1.75rem;
    }
}

@media (max-width: 1280px) {
    .heading--1 {
        font-size: 3rem;
    }

    .heading--2 {
        font-size: 2.5rem;
    }
}

@media (max-width: 1200px) {
    .heading--3 {
        font-size: 2.125rem;
    }

    .heading--4 {
        font-size: 2rem;
    }

    .heading--5 {
        font-size: 1.5rem;
    }

    .excerpt {
        font-size: 1rem;
    }
}

@media (max-width: 1200px) {
    .heading--1 {
        font-size: 2.875rem;
    }
}

@media (max-width: 1024px) {
    html,
    body {
        font-size: 1rem;

        p {
            font-size: 1rem;

            a {
                font-size: 1rem;
            }
        }
    }

    .heading--1 {
        font-size: 2.75rem;
    }

    .heading--2 {
        font-size: 2.25rem;
    }

    .heading--3 {
        font-size: 2rem;
    }

    .heading--4 {
        font-size: 1.75rem;
    }

    .heading--5 {
        font-size: 1.25rem;
    }

    .excerpt {
        padding-bottom: 1.5rem;
    }

    .error-page {
        &__link {
            &:hover {
                background-color: #ededed;
            }
        }
    }
}

@media (max-width: 820px) {
    .heading--1 {
        font-size: 2.5rem;
    }

    .heading--2 {
        font-size: 2.125rem;
    }

    .error-page {
        &__title {
            font-size: 32px;
            margin-bottom: 16px;
        }

        &__desc {
            font-size: 18px;
        }
    }
}

@media (max-width: 640px) {
    .excerpt {
        padding-bottom: 1rem;
    }
}

@media (max-width: 480px) {
    html,
    body {
        p {
            font-size: 0.9375rem;

            a {
                font-size: 0.9375rem;
            }
        }
    }

    .excerpt {
        padding-bottom: 0.75rem;
        font-size: 0.9375rem;
    }

    .heading--1 {
        font-size: 2.25rem;
    }

    .heading--2 {
        font-size: 1.875rem;
    }

    .heading--3 {
        font-size: 1.75rem;
    }

    .heading--4 {
        font-size: 1.5rem;
    }

    .error-page {
        &__title {
            font-size: 28px;
        }

        &__link {
            font-size: 14px;
            padding: 16px 20px;
        }
    }
}

@media (max-width: 414px) {
    .heading--1 {
        font-size: 2.125rem;
    }

    .heading--2 {
        font-size: 1.75rem;
    }

    .heading--3 {
        font-size: 1.5rem;
    }

    .heading--4 {
        font-size: 1.25rem;
    }
}

@media (max-width: 376px) {
    html,
    body {
        p {
            font-size: 0.875rem;

            a {
                font-size: 0.875rem;
            }
        }
    }

    .heading--1 {
        font-size: 2rem;
    }

    .excerpt {
        font-size: 0.875rem;
    }
}

@media (max-width: 340px) {
    .heading--1 {
        font-size: 1.75rem;
    }

    .heading--2 {
        font-size: 1.5rem;
    }
}
